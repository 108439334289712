<template>
  <div class="case-conent">
    <div class="from">
      <iframe
              :srcdoc="content"
              frameborder="no"
              border="0"
              marginwidth="0"
              marginheight="0"
              width="100%"
              scrolling="no"
              id="iframeB"
      >
      </iframe>
<!--      <iframe-->
<!--              :srcdoc="content"-->
<!--              frameborder="no"-->
<!--              border="0"-->
<!--              width="200"-->
<!--              height="200"-->
<!--              scrolling="no"-->
<!--              class="qr-code"-->
<!--              style="overflow: hidden;">-->
<!--      </iframe>-->
    </div>
    <el-dialog
            title="充值提示"
            :visible.sync="Paydialog"
            :modal-append-to-body='false'
            width="600px"
            @close="closeDialog2"
    >
      <div class="PayB">
        <h2><i class="el-icon-success"></i>充值成功！</h2>
        <p>充值：<span>{{payment}}</span> 学币</p>
        <p>花费：<span>{{payment}}</span> 元</p>
      </div>
      <template #footer>
        <div class="dialog-footer" style="padding-bottom: 20px;box-sizing: border-box; text-align: center">
          <el-button @click="GBPay()" round style="border: 1px solid #FF9100; background-color: #FF9100; color: #fff"
                     type="primary">确定
          </el-button>
        </div>
      </template>
    </el-dialog>
  </div>

</template>
<script>
import {payObj} from '@/api/pay'   //路径
import {getOrderObj} from '@/api/recharge'   //路径
import {getStuByUserNameObj} from '@/api/caseHome'   //路径
export default {
  name:'alipay',
  data() {
    return {
      payment:null,
      Paydialog:false,//支付确认
      content:'',
      loading:true,
      orderNo:'',
      status:null,
    }
  },
  created() {
    this.EducationCase = this.$route.query.EducationCase;
    this.clickFlag=this.$route.query.clickFlag;
    this.payment=this.$route.query.payment
    this.stu=this.$route.query.stu;
    this.orderCur=this.$route.query.orderCur
  },
  mounted() {
    this.paySubmit()
  },
  methods:{
    // 查询个人信息
    getStuByUser() {
      getStuByUserNameObj().then((res) => { // eslint-disable-line no-unused-vars
        if (res.code == 200) {
          //this.StuByUser = res.data
          this.$store.commit('saveStuByUser', res.data)
        }
      })

    },
    // 掉支付的接口
    paySubmit() {
      let params = {
        bookId:null,
        folderId:null,
        resourceId:null,
        payment:this.payment,//支付金额
        payType:1,//1支付宝 2 微信
      }
      payObj(params).then((res) => { // eslint-disable-line no-unused-vars
        this.content = res.data.uri;
        this.orderNo = res.data.orderNo
        this.loopOrderState()
        setTimeout(()=>{
          //document.querySelector('body').innerHTML = res.data
          document.forms[0].submit()
        },100)
      })
    },
    // 轮询当前订单状态
    loopOrderState(){
      this.T= setInterval(()=>{
        let obj = {
          orderNo:this.orderNo
        }
        getOrderObj(obj).then((res) => { // eslint-disable-line no-unused-vars
          this.status=res.data.status
          if(res.data.status==1) {// 20s 付款成功 status:1成功
            this.getStuByUser()//刷新个人信息充值金额
            clearInterval(this.T);// 清除掉，否则一直刷

            //充值成功，跳出确认弹框
            this.Paydialog=true
            //然后跳转到去充值页面
            //this.toRecharge()
          }
        })
      },1000)
    },
    // 关闭微信充值提示弹框
    GBPay() {
      //然后跳转到去充值页面
      this.toRecharge()
      this.Paydialog=false
    },
    closeDialog2(){
      //然后跳转到去充值页面
      this.toRecharge()
    },
    // 去充值页面
    toRecharge() {
      this.$router.push({
        path: "/mainb/recharge",
        query: {
          EducationCase: this.EducationCase,
          clickFlag: this.clickFlag,
          stu:this.stu,
          orderCur:this.orderCur
        }
      });
    },
  }
}
</script>
<style lang="scss" scoped>
  .case-conent {
    .from {
      width: 100%;
      height: 100%;
      overflow: hidden;
      /*.qr-code{*/
      /*  align-self: center;*/
      /*  width: 200px;*/
      /*  height: 200px;*/
      /*  margin: 40px auto 20px auto;*/
      /*}*/
      #iframeB {
        /*position: absolute;*/
        /*transform: scale(1, 1) translate(-50%, -50%);*/
        transform: scale(.65) translate(0%, -25%);
        width: 100%;
        height: 1200px;
        box-sizing: border-box;
        margin: auto;

      }
    }
  }
  .PayB {
    width: 90%;
    h2 {
      text-align: center;
      height: 50px;
      line-height: 50px;
      font-size: 25px;
      font-weight: bold;
      padding-bottom: 30px;
      position: relative;
      padding-left: 50px;
      i {
        vertical-align: middle;
        position: absolute;
        font-size: 46px;
        color: #42b983;
        margin-left: -50px;
      }
    }
    p {
      width: 80%;
      margin: auto;
      padding: 6px;
      font-size: 16px;
      span {
        color: #FA6E86;
      }
    }
  }
.loading{
  width: 80%;
  height:80px;
  margin: auto;
  line-height:80px;
  text-align:center;
  padding:30px 0;
  img{
    height:100%;
  }
  h2 {
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    color: #238bff;
  }

}
</style>
